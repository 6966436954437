import React from 'react';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css';
import  Question   from './Question';
import Splash from './Splash.jsx';



function App() {

  return (
    <div>
      <Router>
        <Routes>
            <Route exact path="/" element={<Splash />}/>
            <Route exact path="/Game" element={<Question />}/>    
        </Routes>     
      </Router>
    </div>
  );
}

export default App;

import React from "react";
import { Link } from "react-router-dom"
import AnimatedTitle from "./AnimatedTitle";
import AnimatedGC from "./AnimatedGC";
import LoveIntriguing from "./assets/LoveIntriguing.mp3"


export default function Splash() {

    function playIntro() {
        const eightAwayIntro = new Audio(LoveIntriguing)
        eightAwayIntro.play()
    }

    
    

         return(
                <div className="h-screen mx-auto bg-red-300 flex flex-col items-center justify-center">
                    <AnimatedGC text="Generation Continuum presents" />
                        
                    <div className="p-2 sm:p-4">
                        <AnimatedTitle text="8-Away" />
                    </div>
                    <div className="p-2 sm:p-4">
                        <p className="text-base sm:text-lg text-red-700"><button onClick={playIntro}>Click here to listen our featured short tune, "Love...Intriguing"</button></p>
                    </div>
                    
                    <button className="flex items-center px-2 sm:px-6 py-1 m-2 border-2 border-white
                                text-red-500 bg-white rounded-full"
                                    ><Link to="/Game"><p className="text-base sm:text-xl">Start Game</p></Link></button>
                    
                    <div className="w-1/2 p-2">
                        <p className="text-justify">
                            Valentine's Day is coming soon! So we thought we'd feature this week's theme around  
                            this holiday. All the app's questions are related to dating, love, and sweets. Hope
                            you enjoy! </p> 
                            
                            <p>Thanks for playing!</p>
                    </div>
                    
                </div>
                    
            )
}
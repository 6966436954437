import React from "react";
import { motion } from "framer-motion"
import EightFinale2 from "./assets/EightFinale2.mp3"
import EightFinaleLowScore from "./assets/EightFinaleLowScore.mp3"
import EightAwayMidscore from "./assets/EightAwayMidscore.mp3"


export default function ScoreMessage(props) {

    const eightAwayFinale = new Audio(EightFinale2)
    const eightFinaleLowScore = new Audio(EightFinaleLowScore) 
    const eightAwayMidscore = new Audio(EightAwayMidscore)   

    const finalTotScore = props.finalTotalScore

    if (finalTotScore === 800) {
        props.changeRestart(true)// makes restart button visible and disables choice buttons
        eightAwayFinale.play()
        return(
          <motion.div animate={{ opacity: 1, scale: 1}}                          
                                 transition={{ duration: 0.5 }}
                                 className="text-base sm:text-xl pl-2 sm:pl-4 pr-2 sm:pr-4">
            It's like you made the game yourself!
          </motion.div>
        )
      } else if (finalTotScore >= 700 && finalTotScore < 800) {
        eightAwayFinale.play()
        return(
          <motion.div animate={{ opacity: 1, scale: 1}}                          
                                 transition={{ duration: 0.5 }}
                                 className="text-base sm:text-xl pl-2 sm:pl-4 pr-2 sm:pr-4">
            We're very impressed!!
          </motion.div>
        )
      } else if (finalTotScore >= 600 && finalTotScore < 700) {
        eightAwayFinale.play()
        return(
            <motion.div animate={{ opacity: 1, scale: 1}}                          
                                   transition={{ duration: 0.5 }}
                                   className="text-base sm:text-xl pl-2 sm:pl-4 pr-2 sm:pr-4">
            Nice Job!
          </motion.div>
        )
      } else if (finalTotScore >= 500 && finalTotScore < 600) {
        eightAwayMidscore.play()
        return(
            <motion.div animate={{ opacity: 1, scale: 1}}                          
                                transition={{ duration: 0.5 }}
                                className="text-base sm:text-xl pl-2 sm:pl-4 pr-2 sm:pr-4">
            Not bad at all!
            </motion.div>
        )
      } else if (finalTotScore >= 400 && finalTotScore < 500) {
        eightAwayMidscore.play()
        return(
            <motion.div animate={{ opacity: 1, scale: 1}}                          
                                transition={{ duration: 0.5 }}
                                className="text-base sm:text-xl pl-2 sm:pl-4 pr-2 sm:pr-4">
            Well, okay, good try
            </motion.div>
        )  
      } else if (finalTotScore >= 300 && finalTotScore < 400) {
            eightAwayMidscore.play()
            return(
            <motion.div animate={{ opacity: 1, scale: 1}}                          
                                transition={{ duration: 0.5 }}
                                className="text-base sm:text-xl pl-2 sm:pl-4 pr-2 sm:pr-4">
            Hey, it could've been worse
            </motion.div>
        )
      } else if (finalTotScore >= 200 && finalTotScore < 300) {
        eightFinaleLowScore.play()
        return(
            <motion.div animate={{ opacity: 1, scale: 1}}                          
                                transition={{ duration: 0.5 }}
                                className="text-xs sm:text-xl pl-2 sm:pl-4 pr-2 sm:pr-4">
            
            Mmm, you may want to change your strategy
            </motion.div>
        )
      } else if (finalTotScore >= 100 && finalTotScore < 200) {
        eightFinaleLowScore.play()
        return(
        <motion.div animate={{ opacity: 1, scale: 1}}                          
                               transition={{ duration: 0.5 }}
                               className="text-base sm:text-xl pl-2 sm:pl-4 pr-2 sm:pr-4">
          Uhhh, there are no words...
        </motion.div>
        )
      } else if (finalTotScore >= 0 && finalTotScore < 100) {
        eightFinaleLowScore.play()
        return(
        <motion.div animate={{ opacity: 1, scale: 1}}                          
                               transition={{ duration: 0.5 }}
                               className="text-base sm:text-xl pl-2 sm:pl-4 pr-2 sm:pr-4">
          Just push Restart Game
        </motion.div>
        )
      }

    
}
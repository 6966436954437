import { React, useState, useRef } from "react";
import solutionValentine from "./data/solutionValentine.json"
import { motion } from "framer-motion"
import ScoreMessage from "./ScoreMessage";





export default function Question() {

  const buttonRef = useRef(null)
  const [choices, setChoices] = useState([])
  const [secChoices, setSecChoices] = useState([])
  const [solutions, setSolutions] = useState([])
  const [indexNumber, setIndexNumber] = useState(0)
  const [secindexNumber, setSecindexNumber] = useState([])
  const [secondIndex, setSecondIndex] = useState(0)
  const [flag, setFlag] = useState([])
  const [totalScore, setTotalScore] = useState(0)
  const [finalTotalScore, setFinalTotalScore] = useState()
  const [showFinalTotalScore, setShowFinalTotalScore] = useState(false)
  const [beginScore, setbeginScore] = useState(false) //flag to show score button 
  const [showTotal, setShowTotal] = useState(false)
  const [showTotalScore, setShowTotalScore] = useState()
  const [restart, setRestart] = useState(false) //restarts game
  const [disableButton, setDisableButton] = useState(false)

  const missedQuestions = "Now answer the questions you missed"

  const HandleSubmit = (event) => {

    event.preventDefault()
    const selection = event.target.value
  
     
      setChoices(prevChoices => [...prevChoices, selection] )
      setSolutions(prevSolution => [...prevSolution, solutionValentine[indexNumber].answer])
    
      setIndexNumber(prevNumber => prevNumber + 1)
     
        if (indexNumber > 6) {  
          setbeginScore(true)
          setDisableButton(true)
//          buttonRef.current.focus()
        }
    }
    
    
  

  const finalHandleSubmit = (event) => {

            if (event.target.value === null) {
              return
            }
            event.preventDefault()
            const selection = event.target.value
            
            setSecChoices(prevSecChoices => [...prevSecChoices, selection])
            if (secondIndex < secindexNumber.length - 1) {

              setSecondIndex(prevSecondIndex => prevSecondIndex + 1)

            } else {
              setShowFinalTotalScore(true)
              setDisableButton(true)
            }
                                                             
  }

 const score = (choices, solutions) => {

    let wrong = true
    let right = false
    for(let i = 0; i < choices.length; i++){
        if(choices[i] !== solutions[i]){
            setSecindexNumber(prevSecindex => [...prevSecindex, i])
            setFlag(prevFlag => [...prevFlag, wrong])
        } else {
            setTotalScore(prevTotalScore => prevTotalScore + 100)
            setFlag(prevFlag => [...prevFlag, right])
            
        }
        if (totalScore===800) {
          setDisableButton(true)
        }
    }
    
    setShowTotal(true)
    
  }

    const finalScore = (secChoices) => {

      
      let result = 0
      for(let i = 0; i < secChoices.length; i++) {
          if(secChoices[i] === solutions[secindexNumber[i]]) {
              
              result+=50
          }
      }
      setFinalTotalScore(totalScore + result)

    }
    
function clickScore() { // Get the final total score

    if (!showFinalTotalScore) {
      buttonRef.current.focus()
      score(choices, solutions)
      setShowTotalScore(true)
      setbeginScore(false)//  Turn the score off
      if (totalScore===800) {
        setRestart(true)//  Show Restart button
        } else {
          setDisableButton(false)//  Enable choice buttons
        }
      
    } else
     {
      finalScore(secChoices)
      setRestart(true)
    }
  }  

  console.log(indexNumber)
  console.log(flag)
  console.log(choices)
  console.log(solutions)
  console.log(secChoices)
  console.log(disableButton)
  console.log(secindexNumber)
  console.log(totalScore)
  

    return(
      <div className="mx-auto flex min-h-screen place-content-center items-center justify-center bg-gray-200 pl-10 sm:pl-20 pr-10 sm:pr-20 pb: 8 sm:pb-16">
        <div className="flex flex-col pt-3 sm:pt-5 pl-3 sm:pl-5 pr-3 sm:pr-5 pb-2 sm:pb-4 mt-2 sm:mt-4 sm:mx-auto sm:w-full sm:max-w-md bg-red-300 border-red-500
                        border-2 place-contents-center rounded-xl shadow-md">
            <div className="flex h-36 bg-white border-2 border-red-500 m-2 p-1 sm:p-2 rounded-xl">
              <p className="text-xs sm:text-base">{Array.isArray(secindexNumber) && secindexNumber.length > 0? solutionValentine[secindexNumber[secondIndex]].question : solutionValentine[indexNumber].question}</p>
            </div>
            <div className="flex flex-col h-24 sm:h-32 items-center justify-center text-white text-lg sm:text-2xl">
              {showTotalScore && <motion.div animate={{
                scale: [1, 2, 2, 1, 1],
                rotate: [0, 0, 270, 270, 0],
                borderRadius: ["20%", "20%", "50%", "50%", "20%"],
                }} 
                className="flex items-center justify-center">
                {!showFinalTotalScore  && totalScore} 
              </motion.div>}
              <div className="pl-3  sm:pl-4 pr-3 sm:pr-4">
                {(showTotalScore && !showFinalTotalScore) && ((totalScore!==800) && 
                                                             (<p className="text-base sm:text-xl pl-3 sm:pl-4 pr-3 sm:pr-4">{missedQuestions}</p>))}
              </div>
              <div className="flex flex-col items-center justify-center">
                    {(showTotalScore && (totalScore===800))? <ScoreMessage finalTotalScore={800} changeRestart={() => {setRestart(true)
                                                                                                                      setDisableButton(true)}}/> : <ScoreMessage finalTotalScore={finalTotalScore}/>}
              </div>
              <motion.div animate={{scale: [1, 2, 2, 1, 1]}}> 
                  {showFinalTotalScore && finalTotalScore} 
              </motion.div>
              
              <div className="flex items-center justify-center text-base sm:text-xl">
                {((beginScore || showFinalTotalScore) && !restart) && <button onClick={clickScore} ref={buttonRef} className="flex items-center px-1 sm:px-6 py-1 m-1 border-2 border-white
                                               text-xs sm:text-lg text-red-300 bg-red-500 rounded-full
                                                " >Get Score</button>}
              </div> 
              <div className="flex items-center justify-center sm:text-xl">
                {restart && <button onClick={() => window.location.reload(false)} className="flex items-center px-1 sm:px-6 py-1 m-2 border-2 border-white
                              text-xs sm:text-lg text-red-300 bg-red-500 rounded-full
                              ">Restart Game</button>}
              </div>
            </div>
              
      
          
                <form onSubmit={showTotal? finalHandleSubmit : HandleSubmit}>
                  <fieldset>
                    <div className="flex ">
                        <button
                          type="button"
                          name="choice"
                          id="choiceA"
                          value={Array.isArray(secindexNumber) && secindexNumber.length > 0? solutionValentine[secindexNumber[secondIndex]].choiceA : solutionValentine[indexNumber].choiceA}
                          disabled={disableButton}
                          className="flex w-full items-center justify-center px-3 sm:px-5 py-2 sm:py-2 mt-2 sm:mt-4 m-2 border border-white text-xs sm:text-base font-medium rounded-full shadow-sm 
                              text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                              onClick={showTotal? finalHandleSubmit : HandleSubmit}>                    
                          {Array.isArray(secindexNumber) && secindexNumber.length > 0? solutionValentine[secindexNumber[secondIndex]].choiceA : solutionValentine[indexNumber].choiceA} 
                          </button>
                    </div>
                    <div className="flex">
                        <button
                        type="button"
                        name="choice"
                        id="choiceB"
                        value={Array.isArray(secindexNumber) && secindexNumber.length > 0? solutionValentine[secindexNumber[secondIndex]].choiceB : solutionValentine[indexNumber].choiceB}
                        disabled={disableButton}
                        className="flex w-full items-center justify-center px-3 sm:px-5 py-2 sm:py-2  mt-2 sm:mt-4 m-2 border border-white text-xs sm:text-base font-medium rounded-full shadow-sm 
                                  text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                  onClick={showTotal? finalHandleSubmit : HandleSubmit}>
                        {Array.isArray(secindexNumber) && secindexNumber.length > 0? solutionValentine[secindexNumber[secondIndex]].choiceB : solutionValentine[indexNumber].choiceB}
                        </button>
                    </div>
                    <div className="flex">
                        <button
                        type="button"
                        name="choice"
                        id="choiceC"
                        value={Array.isArray(secindexNumber) && secindexNumber.length > 0? solutionValentine[secindexNumber[secondIndex]].choiceC : solutionValentine[indexNumber].choiceC}
                        disabled={disableButton}
                        className="flex w-full items-center justify-center px-3 sm:px-5 py-2 sm:py-2 mt-2 sm:mt-4 m-2 border border-white text-xs sm:text-base font-medium rounded-full shadow-sm 
                                  text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                  onClick={showTotal? finalHandleSubmit : HandleSubmit}>
                        {Array.isArray(secindexNumber) && secindexNumber.length > 0? solutionValentine[secindexNumber[secondIndex]].choiceC : solutionValentine[indexNumber].choiceC}  
                        </button>
                    </div>
                    <div className="flex">
                        <button
                        type="button"
                        name="choice"
                        id="choiceD"
                        value={Array.isArray(secindexNumber) && secindexNumber.length > 0? solutionValentine[secindexNumber[secondIndex]].choiceD : solutionValentine[indexNumber].choiceD}
                        disabled={disableButton}
                        className="flex w-full items-center justify-center px-3 sm:px-5 py-2 sm:py-2 mt-2 sm:mt-4 m-2 border border-white text-xs sm:text-base font-medium rounded-full shadow-sm
                              text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                              onClick={showTotal? finalHandleSubmit : HandleSubmit}>
                        {Array.isArray(secindexNumber) && secindexNumber.length > 0? solutionValentine[secindexNumber[secondIndex]].choiceD : solutionValentine[indexNumber].choiceD}
                        </button>
                    </div>
                  </fieldset>  
                </form>  
          </div>
        </div>
    
    )
}

  